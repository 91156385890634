<template>
  <div class="flexCol alignItems">
    <div class="content">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>商品管理</el-breadcrumb-item>
        <el-breadcrumb-item>优惠券管理</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="content_li">
        <div class="top-search">
          <div class="flexRow justifyContentSpac">
            <div class="flexRow">
              <div class="flexRow alignItems margin-btn">
                <div class="search-title">优惠券名称：</div>
                <el-input v-model="search.couponName" placeholder="搜索"></el-input>
              </div>
              <div class="flexRow alignItems margin-btn">
                <div class="search-title">优惠券状态：</div>
                <el-select v-model="search.couponStatus" placeholder="请选择">
                  <el-option
                      v-for="item in couponStatus"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div class="flexRow justifyContentSpac mr-top">
            <div class="flexRow">
              <div class="flexRow alignItems margin-btn">
                <div class="search-title">优惠券类型：</div>
                <el-select v-model="search.couponType" placeholder="请选择">
                  <el-option
                      v-for="item in couponType"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </div>
              <div class="flexRow alignItems margin-btn">
                <div class="search-title">有效期限：</div>
                <el-date-picker
                    v-model="datePicker"
                    value-format="yyyy-MM-dd HH:mm"
                    format="yyyy-MM-dd HH:mm"
                    type="datetimerange"
                    @change="changePicker"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                ></el-date-picker>
              </div>
              <el-button type="primary" @click="searchData" style="margin-left:20px;">搜索</el-button>
              <el-button @click="initSearch" style="margin-left:20px;">重置</el-button>
            </div>
            <div class="flexRow">
              <el-button type="primary" v-if="couponAdd" @click="goAdd">新增优惠券</el-button>
            </div>
          </div>
        </div>
        <div class="table-content">
          <el-table
              border
              :data="tableList"
              v-loading="tableLoading"
              style="width: 100%"
              :header-cell-style="this.utils.getRowClass"
          >
            <el-table-column align="center" type="index" label="序号" width="80"></el-table-column>
            <el-table-column align="center" prop="couponName" label="优惠券名称"></el-table-column>
            <el-table-column align="center" label="图片" min-width="60">
              <template slot-scope="scope"><img @click="handleSee(scope.row.couponImages)" class="icon-img"
                                                :src="scope.row.couponImages"/></template>
            </el-table-column>
            <el-table-column align="center" label="类型" min-width="40">
              <template slot-scope="scope">{{ scope.row.couponType  | getCouponType }}</template>
            </el-table-column>
            <el-table-column align="center" prop="satisfy" label="条件">
              <template slot-scope="scope">{{scope.row.satisfy.toFixed(2) }}元</template>
            </el-table-column>
            <el-table-column align="center" prop="discountAmount" label="优惠金额/折扣/直减金额"
                             :min-width="100">
              <template slot-scope="scope">
                <span v-if="scope.row.couponType === 2">{{ scope.row.discountAmount}}折</span>
                <span v-else>{{ scope.row.discountAmount.toFixed(2)}}元</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="points" label="所需积分" min-width="60"></el-table-column>
            <el-table-column align="center" label="优惠券状态" min-width="60">
              <template slot-scope="scope">{{ scope.row.couponStatus  | getCouponStatus }}</template>
            </el-table-column>
            <el-table-column align="center" label="有效期限" :min-width="150">
              <template slot-scope="scope">{{ scope.row.effectiveStart }}至{{ scope.row.effectiveEnd }}</template>
            </el-table-column>
            <el-table-column align="center" prop="createUserName" label="创建人" min-width="60"></el-table-column>
            <el-table-column align="center" prop="updateUserName" label="最后修改人" min-width="60"></el-table-column>
            <el-table-column align="center" label="操作" min-width="150">
              <template slot-scope="scope">
                <div class="flex flex_row flex_ju_center">
                  <div class="border_center_left" v-if="couponUpd && scope.row.couponStatus === 1" @click="edit(scope.row)">编辑</div>
                  <div class="border_center_left" v-if="couponShelves" @click="changeStatus(scope.row)">
                    {{ scope.row.couponStatus === 1 ? '上架' : '下架' }}
                  </div>
                  <div class="border_center_left" v-if="couponDel" @click="del(scope.row.id)">删除</div>
                  <div class="border_center_left" @click="details(scope.row)">详情</div>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="flexRow justifyContentSpac" style="margin-top:20px">
            <div></div>
            <el-pagination
                @size-change="handleSizeChange"
                background
                layout="total, prev, pager, next,sizes"
                :total="total"
                :page-sizes="[10, 15, 20, 30, 50, 100]"
                :page-size="pageSize"
                @current-change="changePage"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
        :title="type === 'details' ? '详情' : type === 'edit' ? '修改' : '新增'"
        :close-on-click-modal="false"
        :visible.sync="dialogVisible"
        width="60%"
        :before-close="handleClose"
    >
      <el-form v-if="upData && formDataReady" class="flexGrid" :model="upData" :rules="rules" ref="ruleForm">
        <el-form-item label="优惠券名称：" prop="couponName">
          <span v-if="type === 'details'"> {{ upData.couponName }}</span>
          <el-input v-else v-model="upData.couponName" placeholder="请输入优惠券名称"></el-input>
        </el-form-item>
        <el-form-item label="所需积分：" prop="points">
          <span v-if="type === 'details'"> {{ upData.points }}</span>
          <el-input-number v-else type="number" :min="0" :step-strictly="true" :controls="false" :step="1"
                           v-model="upData.points" placeholder="请输入所需积分"></el-input-number>
        </el-form-item>
        <el-form-item label="发行量：(-1：为不限数量)">
          <span v-if="type === 'details'"> {{ upData.circulation }}</span>
          <el-input-number v-else type="number" :min="-1" :step-strictly="true" :controls="false" :step="1"
                           v-model="upData.circulation" placeholder="请输入优惠券发行量"></el-input-number>
        </el-form-item>
        <el-form-item label="优惠券类型：" prop="couponType">
          <span v-if="type === 'details'">
            {{ upData.couponType  | getCouponType }}
          </span>
          <el-select v-else v-model="upData.couponType" placeholder="请选择优惠券类型">
            <el-option
                v-for="item in couponType"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="条件" prop="satisfy" v-if="upData.couponType !== 3">
          <span v-if="type === 'details'"> {{ upData.satisfy }}</span>
          <div v-else>
            <el-input-number type="number" :step-strictly="true" :controls="false" :step="0.01"
                             :precision="2" :min="0" v-model="upData.satisfy" placeholder="请输入条件"></el-input-number>
          </div>
        </el-form-item>
        <el-form-item label="结果：" prop="discountAmount">
          <span v-if="type === 'details'"> {{ upData.discountAmount }}</span>
          <div v-else>
            <el-input-number v-if="upData.couponType === 1" type="number" :step-strictly="true" :controls="false"
                             :step="0.01"
                             :precision="2" :min="0" v-model="upData.discountAmount"
                             placeholder="请输入优惠金额"></el-input-number>
            <el-input-number v-if="upData.couponType === 2" type="number" :step-strictly="true" :controls="false"
                             :step="0.01"
                             :precision="2" :min="0" v-model="upData.discountAmount"
                             placeholder="请输入折扣"></el-input-number>
            <el-input-number v-if="upData.couponType === 3" type="number" :step-strictly="true" :controls="false"
                             :step="0.01"
                             :precision="2" :min="0" v-model="upData.discountAmount"
                             placeholder="请输入直减金额"></el-input-number>
          </div>
        </el-form-item>
        <el-form-item label="有效期限：" prop="effectiveStart">
          <span v-if="type === 'details'">{{upData.effectiveStart }}至{{upData.effectiveEnd }}</span>
          <el-date-picker
              v-else
              v-model="datePicker1"
              value-format="yyyy-MM-dd HH:mm"
              format="yyyy-MM-dd HH:mm"
              type="datetimerange"
              @change="changePicker1"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
<!--        <el-form-item v-if="type !== 'details'"></el-form-item>-->
        <el-form-item label="优惠券描述：">
          <span v-if="type === 'details'">{{upData.couponDesc }}</span>
          <el-input
              v-else
              type="textarea"
              placeholder="请输入优惠券描述"
              v-model="upData.couponDesc"
              :autosize="{ minRows: 6, maxRows: 8}"
              maxlength="20"
              show-word-limit
          />
        </el-form-item>
        <el-form-item label="优惠券图片：" prop="couponImages">
          <div class="flexRow images-item">
            <div class="up-image" v-if="type === 'details'">
              <div class="image-list">
                <img @click="handleSee(upData.couponImages)" :src="upData.couponImages" alt/>
              </div>
            </div>
            <div class="up-image" v-else>
              <div v-for="(item, index) in upList" class="image-list" :key="index">
                <img @click="handleSee(item.url)" :src="item.url" alt/>
                <i class="el-icon-error" @click="removeImage(index)"></i>
              </div>
            </div>
            <el-upload
                v-if="type !== 'details'"
                action
                accept=".jpg, .jpeg, .png, .gif"
                :on-exceed="onExceed"
                :on-error="onError"
                :file-list="upList"
                list-type="picture-card"
                :show-file-list="false"
                :auto-upload="true"
                :http-request="fnUploadRequest"
                :before-upload="beforeFileUpload"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
          </div>
        </el-form-item>

      </el-form>
      <span v-if="type === 'details'" slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleClose">返 回</el-button>
      </span>
      <span v-else slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="add" v-if="type !== 'details'">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog :visible.sync="dialogImgVisible">
      <div class="flexRow justifyContentCenter alignItems">
        <img width="70%" :src="dialogImgUrl" alt=""></div>
    </el-dialog>
  </div>
</template>

<script>
let _this;
export default {
  data() {
    _this = this;
    const validatorCouponImages = (rule, value, callback) => {
      if (this.upData.couponImages === "") {
        callback(new Error("请上传图片"));
      } else {
        callback();
      }
    }
    const validatePoints = (rule, value, callback) => {
      if (value === 0 || value === undefined) {
        callback(new Error('请输入所需积分'));
      } else {
        callback();
      }
    };



    const validatorSatisfy = (rule, value, callback) => {
      if (value === 0 || value === undefined) {
        callback(new Error('请输入条件'));
      } else {
        callback();
      }
    };
    const validatorDiscountAmount = (rule, value, callback) => {
      if (value === 0 || value === undefined) {
        callback(new Error('请输入结果'));
      } else {
        callback();
      }
    };
    return {
      search: {
        couponStatus: null,
        couponName: "",
        couponType: null,
        effectiveEnd: "",
        effectiveStart: "",
      },
      type: "",
      tableList: [],
      upList: [],
      dialogVisible: false,
      dialogImgVisible: false,
      tableLoading: false,
      btnLoading: false,
      formDataReady: true,
      dialogImgUrl: "",
      upData: {
        circulation: -1,
        couponDesc: "",
        couponStatus: 1,
        couponName: "",
        couponType: 1,
        discountAmount: null,
        effectiveEnd: "",
        effectiveStart: "",
        couponImages: "",
        points: null,
        satisfy: null
      },
      couponStatus: [
        {label: '下架', value: 1},
        {label: '上架', value: 2}
      ],
      couponType: [
        {label: '满减', value: 1},
        {label: '打折', value: 2},
        {label: '直减', value: 3}
      ],
      rules: {
        couponName: [{required: true, message: "请输入优惠券名称", trigger: "blur"}],
        couponType: [{required: true, message: "请选择优惠券类型", trigger: "change"}],
        effectiveStart: [{required: true, message: "请选择优惠券有效期限", trigger: "change"}],
        satisfy: [{required: true, validator: validatorSatisfy, trigger: "change"}],
        discountAmount: [{required: true, validator: validatorDiscountAmount, trigger: "change"}],
        couponImages: [{required: true, validator: validatorCouponImages, trigger: "change"}],
        points: [{required: true, validator: validatePoints, trigger: "blur"}],
      },
      couponAdd: false,
      couponDel: false,
      couponUpd: false,
      couponShelves: false,
      total: 0,
      page: 1,
      pageSize: 10,
      datePicker: [],
      datePicker1: [],
    };
  },
  filters: {
    getCouponStatus(value) {
      const data = _this.couponStatus.filter(item => item.value == value);
      if (data && data.length > 0) {
        return data[0].label;
      }
    },
    getCouponType(value) {
      const data = _this.couponType.filter(item => item.value == value);
      if (data && data.length > 0) {
        return data[0].label;
      }
    },
  },
  mounted() {
    this.getMenuBtn();
    this.getData();
  },
  methods: {
    getData() {
      let _this = this;
      _this.tableLoading = true;
      const requestData = {
        page: this.page,
        pageSize: this.pageSize,
        search: {}
      }
      // 过滤筛选
      const filterData = JSON.parse(JSON.stringify(this.search));
      for (let key in filterData) {
        if (filterData[key]) {
          requestData.search[key] = filterData[key];
        }
      }

      _this.$api
          .getCoupon(requestData)
          .then(res => {
            if (res.data.code == 200) {
              _this.total = res.data.data.total;
              const data = JSON.parse(JSON.stringify(res.data.data.records));
              data.map(item => {
                item.discountAmount = item.discountAmount/100;
                item.satisfy = item.satisfy/100;
              })
              _this.tableList = data;
              _this.tableLoading = false;
            }
          }).catch(() => {
        _this.tableLoading = false;
      });
    },
    // 获取按钮权限
    getMenuBtn() {
      let _this = this;
      const list = JSON.parse(localStorage.getItem("jurisdictionList"));

      list.forEach((v) => {
        if (v == "coupon:add") {
          _this.couponAdd = true;
        }
        if (v == "coupon:del") {
          _this.couponDel = true;
        }
        if (v == "coupon:upd") {
          _this.couponUpd = true;
        }
        if (v == "coupon:shelves") {
          _this.couponShelves = true;
        }
      })
    },
    // 初始化表单
    handleInitForm() {
      let _this = this;
      _this.formDataReady = false;
      _this.$nextTick(() => {
        _this.formDataReady = true;
      });
    },
    // 浏览图片
    handleSee(e) {
      let _this = this;
      _this.dialogImgUrl = e;
      _this.dialogImgVisible = true;
    },
    // 有效期
    changePicker(e) {
      this.search.effectiveStart = e[0];
      this.search.effectiveEnd = e[1];
    },
    changePicker1(e) {
      this.upData.effectiveStart = e[0];
      this.upData.effectiveEnd = e[1];
    },
    add() {
      let _this = this;
      _this.btnLoading = true;
      _this.$refs["ruleForm"].validate(valid => {
        if (valid) {

          const resquestData = JSON.parse(JSON.stringify(_this.upData))
          resquestData.satisfy = resquestData.satisfy*100;
          resquestData.discountAmount = resquestData.discountAmount*100;

          if (!_this.upData.id) {
            _this.$api.addCoupon(resquestData).then(res => {
              if (res.data.code == 200) {
                _this.$message.success("添加成功");
                _this.btnLoading = false;
                _this.handleClose();
                _this.getData();
              }
            }).catch(() => {
              _this.btnLoading = false;
            });
          } else {
            _this.$api.editCoupon(resquestData).then(res => {
              if (res.data.code == 200) {
                _this.$message.success("修改成功");
                _this.btnLoading = false;
                _this.getData();
                _this.handleClose();
              }
            }).catch(() => {
              _this.btnLoading = false;
            });
          }
        } else {
          _this.btnLoading = false;
        }
      });
    },
    // 上下架
    changeStatus(val) {
      const data = JSON.parse(JSON.stringify(val));
      const title = data.couponStatus === 1 ? '上架' : '下架'

      let _this = this;
      _this.$confirm(`此操作将${title}该数据, 是否继续?`, '提示', {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info"
      })
          .then(() => {
            _this.$api.shelvesCoupon({id: val.id}).then(res => {
              if (res.data.code == 200) {
                if (res.data.data) {
                  _this.$message.success(`${title}成功`);
                  _this.getData();
                } else {
                  _this.$message.error(`${title}失败`);
                }
              }
            });
          })
          .catch(() => {

          });

    },
    handleClose() {
      let _this = this;
      _this.upData = {
        circulation: -1,
        couponDesc: "",
        couponStatus: 1,
        couponName: "",
        couponType: 1,
        discountAmount: null,
        effectiveEnd: "",
        effectiveStart: "",
        couponImages: "",
        points: null,
        satisfy: null
      }
      _this.handleInitForm();
      _this.dialogVisible = false;
    },
    changePage(e) {
      let _this = this;
      _this.page = e;
      _this.getData();
    },
    handleSizeChange(e) {
      let _this = this;
      _this.page = 1;
      _this.pageSize = e;
      _this.getData();
    },
    initSearch() {
      let _this = this;
      _this.search = {
        couponStatus: null,
        couponName: "",
        couponType: null,
        effectiveEnd: "",
        effectiveStart: "",
      }
      _this.page = 1;
      _this.datePicker = [];
      _this.getData();
    },
    searchData() {
      let _this = this;
      _this.page = 1;
      _this.getData();
    },

    goAdd() {
      let _this = this;
      _this.dialogVisible = true;
      _this.type = 'add';
      _this.upData = {
        circulation: -1,
        couponDesc: "",
        couponStatus: 1,
        couponName: "",
        couponType: 1,
        discountAmount: null,
        effectiveEnd: "",
        effectiveStart: "",
        couponImages: "",
        points: null,
        satisfy: null
      }
      _this.upList = [];
      _this.datePicker1 = [];
      _this.handleInitForm();
    },
    details(e) {
      let _this = this;
      _this.dialogVisible = true;
      _this.type = 'details';
      _this.upData = JSON.parse(JSON.stringify(e));
      _this.upList = [];
      _this.upList.push({
        url: _this.upData.couponImages
      });
    },
    edit(e) {
      let _this = this;
      _this.dialogVisible = true;
      _this.type = 'edit';
      _this.upData = JSON.parse(JSON.stringify(e));
      _this.datePicker1 = [_this.upData.effectiveStart, _this.upData.effectiveEnd];
      _this.upList = [];
      _this.upList.push({
        url: _this.upData.couponImages
      });
    },
    del(id) {
      let _this = this;
      _this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info"
      })
          .then(() => {
            _this.$api.delCoupon({id}).then(res => {
              if (res.data.code == 200) {
                if (res.data.data) {
                  _this.$message.success('删除成功');
                  _this.getData();
                } else {
                  _this.$message.error('删除失败');
                }
              }
            });
          })
          .catch(() => {
            _this.$message.error('删除失败');
          });
    },
    // 上传start
    handleRemove() {
      let _this = this;
      _this.couponImages = [];
    },
    handlePictureCardPreview(file) {
      let _this = this;
      _this.dialogImageUrl = file.url;
      _this.dialogVisible = true;
    },
    onExceed() {
      let _this = this;
      _this.$message.error("图片最多上传2张");
    },
    removeImage(e) {
      let _this = this;
      _this.upList.splice(e, 1);
      _this.upData.couponImages = "";
    },
    onError() {
      let _this = this;
      _this.$message.error("图片大小不能超过2M");
    },
    beforeFileUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      let _this = this;

      if (!isLt2M) {
        _this.$message.error('上传图片大小不能超过 2MB!');
      }
      return isLt2M;
    },
    fnUploadRequest(e) {
      let _this = this;
      _this.utils.upFile(e.file).then(res => {
        _this.upList = [];
        _this.upList.push(res);
        _this.upData.couponImages = res.url;
      });
    }
    // 上传end
  }
};
</script>
<style scoped lang="less">
/deep/ .el-form-item {
  width: 90%;
}

/deep/ .el-input__count {
  background-color: #f8fafb;
}

.between-text {
  margin: 0 10px;
}

.mr-top {
  margin-top: 20px;
}

/deep/ .el-input-number {
  width: 100%;
}

/deep/ .el-range-editor {
  width: 100%;
}

/deep/ .el-input-number .el-input__inner {
  text-align: left;
}

.flexGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  //margin: 20px 0;

  /deep/ .el-form-item {
    flex-direction: column;
    justify-content: space-between;
  }
}

/deep/ .el-range-input {
  background-color: #fff;
}

.images-item {
  width: 100%;
}

.search-title {
  width: 100px;
}
</style>
